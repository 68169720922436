@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
    font-family: 'KeepCalm';
    src: url('/fonts/KeepCalm-Medium.eot');
    src: url('/fonts/KeepCalm-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/KeepCalm-Medium.woff2') format('woff2'),
        url('/fonts/KeepCalm-Medium.woff') format('woff'),
        url('/fonts/KeepCalm-Medium.ttf') format('truetype'),
        url('/fonts/KeepCalm-Medium.svg#KeepCalm-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kroftsmann';
    src: url('/fonts/Kroftsmann.eot');
    src: url('/fonts/Kroftsmann.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Kroftsmann.woff2') format('woff2'),
        url('/fonts/Kroftsmann.woff') format('woff'),
        url('/fonts/Kroftsmann.ttf') format('truetype'),
        url('/fonts/Kroftsmann.svg#Kroftsmann') format('svg');
    font-weight: normal;
    font-style: normal;
}
