.home {
    background: $green;

    &__button {
        background: transparent;
        border: none;
        width: 150px;
        height: 50px;
        position: relative;
        color: $black;
        display: inline-block;

        &__back {
            display: block;
            background: $yellow;
            transform: skewX(-20deg);
            position: absolute;
            top: 0;
            bottom:0;
            left:0;
            right:0;
            z-index: 0;
            box-shadow: 0 0 20px rgba(0,0,0,.2);
        }

        &__text {
            position: absolute;
            z-index: 2;
            top:10px;
            font-size: 21px;
            left:0;
            right:0;
            text-align: center;
        }
    }

    &__hero {
        min-height: 1110px;
        background: url('/images/landing/hero-mobile.jpg') no-repeat top center / cover;

        @include mq(md){
            min-height: 56.5vw;
            background: url('/images/landing/hero-desktop.jpg') no-repeat top center / cover;
        }

        &__col {
            text-align: center;
            text-transform: uppercase;
            padding-top: 115vw;

            @include mq(md){
                padding-top: 8.5vw;
            }
        }

        &__logo {
            width: 250px;

            @include mq(md){
                width: auto;
            }
        }

        &__title {
            margin-top: 0;
            font-size: 24px;

            @include mq(md){
                font-size: 42px;
            }
        }

        &__text {
            margin-top: 15px;
            font-size: 14px;

            @include mq(md){
                font-size: 16px;
            }

            br {
                display: none;

                @include mq(md){
                    display: block;
                }
            }
        }

        &__disclaimer {
            font-size: 11px;
            text-transform: none;
            margin-bottom: 0;

            @include mq(md) {
                font-size: 13px;
            }
        }

        &__form {
            padding-bottom: 15px;

            &__input {
                border: none;
                background: $black;
                width: 80%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 20px;
                box-shadow: -2px 2px 5px rgba(255,255,255,.6);
                color: $yellow;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 15px;

                @include mq(md) {
                    width: 100%;
                }

                &::placeholder{
                    @include trans;
                    color: $yellow;
                    opacity: .7;
                }

                &:focus {
                    outline:none;
                    box-shadow: -2px 2px 5px rgba(255,255,255,.6);

                    &::placeholder{
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__prizes {
        min-height: 160vw;
        background: url('/images/landing/prizes-mobile.jpg') no-repeat top center / cover;
        text-align: center;
        color: $white;
        position: relative;

        @include mq(md){
            min-height: 64vw;
            margin-bottom: 0;
            background: url('/images/landing/prizes-desktop.jpg') no-repeat top center / cover;
        }

        &__title {
            color: $yellow;
            font-size: 42px;

            @include mq(md){
                font-size: 54px;
            }
        }

        &__text {
            margin-top: 15px;
            font-size: 12px;

            @include mq(md){
                font-size: 18px;
            }
        }

        &__slider {
            position: relative;
            top: 30vw;

            @include mq(md) {
                display: none;
            }
        }
    }

    &__comercial {
        min-height: 110vw;
        background: url('/images/landing/video-mobile.jpg') no-repeat top center / cover;
        text-align: center;
        position: relative;

        @include mq(md){
            min-height: 54vw;
            background: url('/images/landing/video-desktop.jpg') repeat-y center top / cover;
        }

        &__col {
            min-height: 110vw;

            @include mq(md){
                min-height: 54vw;
            }
        }

        &__video {
            position: relative;
            margin-top: 15%;
        }

        &__splatter {
            position: absolute;
            left:0;
            right:0;
            margin: auto;
            top:-5%;
            width: 75%;
            z-index: 0;

            @include mq(md){
                top:0;
                width: 55%;
            }
        }

        &__deadpool {
            display: none;

            @include mq(md){
                display: block;
                position: absolute;
                left:-18%;
                bottom: 0;
                z-index: 2;
                width: 32%;
                //transform: translateX(-60%);
            }
        }

        &__cable {
            display: none;

            @include mq(md){
                display: block;
                position: absolute;
                right:-20%;
                bottom: 0;
                z-index: 2;
                //transform: translateX(75%);
                width: 30%;
            }
        }

        &__bottles {
            position: absolute;
            right:0;
            bottom: 15vw;
            z-index: 2;

            @include mq(md){
                bottom: 0;
                right:-20%;
                //transform: translateX(130%);
            }
        }
    }
}
