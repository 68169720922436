.footer {
    background: $yellow;
    position: relative;
    margin-top: -17vw;

    @include mq(md) {
        margin-top: 0;
    }

    &__top {
        background: $black;
        height: 40px;

        @include mq(md){
            height: 75px;
        }
    }

    &__social {
        display: flex;
        height: 40px;
        align-items: center;

        @include mq(md){
            height: 75px;
        }

        li {
            margin-left: 10px;
        }

        a {
            font-size: 18px;
            line-height: 40px;
            display: block;
            width: 40px;
            height: 40px;
            text-align: center;

            @include mq(md){
                font-size: 24px;
            }
        }
    }

    &__logo {
        position: absolute;
        left:15px;
        top: -15px;
        height: 70px;
        width: auto;

        @include mq(md){
            top: -25px;
            height: 130px;
        }
    }

    &__bottom {
        padding: 20px 0;
        text-align: center;

        @include mq(md) {
            text-align: right;
        }

        a {
            text-transform: uppercase;
            color: $black;
            font-size: 14px;
        }
    }
}
