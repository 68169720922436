.header {

    $h: &;

    @include trans;
    position: fixed;
    top: 0;
    width: 100%;
    align-items: center;
    z-index: 1000;

    @include mq(md) {
        background: linear-gradient(to left, rgba(255,240,0,.8)20%,  rgba(255,240,0,.8) 60%, transparent 80%);
    }

    .container {
        position: relative;
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 30px;
        top: 20px;
        z-index: 100;

        span {
            background-color: $white;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        @include mq(md) {
            display: none;
        }
    }

    &__logo {
        @include trans;
        position: absolute;
        left:15px;

        img {
            width: 120px;
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        padding-top: 64px;
        top: 0;
        bottom:0;
        right:0;
        width:100%;
        background: url('/images/landing/headerback.png') no-repeat top center / cover;
        transform: translateX(100%);
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include mq(md) {
            position: relative;
            flex-shrink: 1;
            display: flex;
            width: 100%;
            transform: translateX(0);
            padding-top: 0;
            background: none;
            justify-content: flex-end;
            flex-direction: row;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 15px;
        }
    }

    &__menu {

        @include mq(md){
           display: flex;
           justify-content: flex-start;
           text-align: left;
           margin-bottom: 0;
        }

        & > li {
            position: relative;

            @include mq(md){
                margin-left: 40px;
            }
        }

        & > li > a{
            @include trans;

            color:$white;
            font-weight: 500;
            font-size: $xl;
            display: block;
            padding: 0 40px;
            height: 50px;
            display: block;
            line-height: 50px;
            text-transform: uppercase;
            text-align: center;


            @include mq(md){
                color: $black;
                position: relative;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.1px;
                height: 40px;
                display: flex;
                line-height: 40px;
                margin-bottom: 0;
                padding: 0;
                justify-content: space-between;
                align-items: center;

                &:before {
                    @include trans;

                    content: '';
                    opacity: 0;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right:0;
                    top:0;
                    background: url('/images/landing/header-banner.png') no-repeat center center / 100% 100%;
                    z-index: -1;
                }
            }

            i.material-icons {
                display: none;

                @include mq(md) {
                    display: inline-block;
                    color: $lightGray;
                    margin-left: 10px;
                }
            }

            &:hover {
                color: $white;
                padding-left: 30px;
                padding-right: 30px;

                @include mq(md){
                    color: $black;
                }

                &:before {
                    opacity: 1;
                }
            }

            &:focus {
                text-decoration: none;
            }
        }
    }

    &__social {
        display: flex;
        justify-content: center;

        @include mq(md){
            margin-left: 15px;
        }

        li{
            padding: 20px;

            @include mq(md){
                padding: 0;
                margin-left: 15px;
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 2px solid $white;
            color: $white;
        }
    }

    &.-ontop {
        background: transparent;

        #{$h}__menu {
            li {
                a {
                    &:before {

                    }
                }
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
