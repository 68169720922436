.intro {
    background: $black url('/images/intro/bg-mobile.jpg') no-repeat center center / cover;
    position: relative;
    min-height: 100vh;
    font-family: $altFont;
    color: $white;

    @include mq(md){
        background: $black url('/images/intro/bg-desktop.jpg') no-repeat top center / cover;
    }


    &__top,
    &__stage {
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
    }

    &__stage {
        z-index: 1;
        text-align: center;
        padding-top: 38%;
        background: url('/images/intro/bottom-ray-mobile.png') no-repeat bottom center / contain;

        @include mq(md){
            padding-top: 18%;
            background: url('/images/intro/bottom-ray-desktop.png') no-repeat bottom center / contain;
        }

        &__data {
            @include mq(md) {
                position: absolute;
                right:10%;
                left:50%;
            }
        }

        &__one,
        &__two {
            display: none;

            &.-visible {
                display: block;
            }
        }

        &__two {
            .intro__title {
                @include mq(md){
                    margin-bottom: 10px;
                    font-size: 5vw;
                }
            }

            .intro__subtitle {
                @include mq(md){
                    margin-bottom: 20px;
                    font-size: 2.7vw;
                }
            }
        }
    }

    &__top {
        z-index: 2;
        pointer-events: none;
    }

    &__banner {
        position: absolute;
        top:0;
        left:0;
        width: 90%;

        @include mq(md) {
            width: 55%;
        }
    }

    &__bottle {
        position: absolute;
        bottom:0;
        left:-20px;
        width: 30%;

        @include mq(md) {
            width: 15%;
        }
    }

    &__logo {
        position: absolute;
        top:15px;
        right:15px;
        width: 30%;

        @include mq(md) {
            top:25px;
            right:20px;
            width: 12%;
        }
    }

    &__title {
        color: $yellow;

        @include mq(md){
            font-size: 4vw;
            line-height: .9;
            margin-bottom: 0;
        }
    }

    &__subtitle {
        font-size: 24px;

        @include mq(md){
            font-size: 3vw;
            margin-top: 0;
        }
    }

    &__deadpool {
        position: absolute;
        left: 20px;
        bottom: 0;
        width: 80%;
        z-index: 0;
        pointer-events: none;

        @include mq(md) {
            width: 53%;
            left: 8%;
        }

        &--forbid {
            @include mq(md) {
                width: 40%;
                left: 10%;
            }
        }
    }

    &__input {
        background: url('/images/intro/red-banner.png') no-repeat center center / cover;
        min-height: 100px;
        display: flex;
        align-items: center;
        font-size: 28px;
        justify-content: center;
        padding-top: 15px;
        width: 80%;
        margin: auto;

        @include mq(md){
            height: 200px;
            font-size: 48px;
        }

        &__day,
        &__month,
        &__year {
            background: transparent;
            border:none;
            color: $white;
            text-align: center;

            &::placeholder {
                @include trans;
                color: $white;
                opacity: .7;
            }

            &:focus {
                outline: none;
                box-shadow: none;

                &::placeholder {
                    opacity: 1;
                }
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            -moz-appearance:textfield;
        }

        &__day,
        &__month{
            width: 50px;

            @include mq(md){
                width: 80px;
            }
        }

        &__year {
            width: 75px;

            @include mq(md){
                width: 110px;
            }
        }
    }

    &__button {
        $b:&;

        @include trans;
        background: transparent;
        border: none;
        width:120px;
        height: 50px;
        position: relative;
        color: $black;
        float: right;
        margin-right: 30px;
        pointer-events: all;

        @include mq(md){
            width: 200px;
            height: 70px;
        }

        &__back {
            @include trans;
            display: block;
            background: $yellow;
            transform: skewX(-20deg);
            position: absolute;
            top: 0;
            bottom:0;
            left:0;
            right:0;
            z-index: 0;
        }

        &__text {
            position: absolute;
            z-index: 2;
            top:10px;
            font-size: 24px;
            left:0;
            right:0;
            text-align: center;

            @include mq(md){
                top: 7px;
                font-size: 42px;
            }
        }

        &.-disabled {
            pointer-events: none;
            opacity: .3;
        }
    }
}
